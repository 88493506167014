.header-icon {
    width: 30px;
    background-color: #f59e0b;
    height: 3px;
    border-radius: 5px
}
.overview-header-first-icon {
    width: 450px;
    background-color: #d6d3e0;
    height: 1px;
    border-radius: 5px
}